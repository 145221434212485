import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../Components/AppLayout/DashboardLayout/sidebar";
import DashboardHeader from "../../Components/AppLayout/DashboardLayout/header";
import { useGlobalContext } from "../../context";

const DashboardWrapper = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const {
    getDashboard,
    getProfile,
    getAllBanks,
    getReferralTrans,
    getWalletBalance,
    getWalletTrans,
    getBankDetails,
    getNotifications,
    page,
    getNews,
    getTransactions,
  } = useGlobalContext();

  useEffect(() => {
    getProfile();
    getDashboard();
    getWalletBalance();

    getAllBanks();
    getBankDetails();
    getNotifications();
    getNews();
  }, []);

  useEffect(() => {
    getWalletTrans();
    getReferralTrans();
    getTransactions();
  }, [page]);

  return (
    <div className='max-width2 relative overflow-x-hidden'>
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:block lg:w-[25%] xl:w-[20%] fixed top-0 left-0 z-10 bg-white transition`}
      >
        <DashboardSidebar closeSidebar={closeSidebar} />
      </div>
      <div className='lg:w-[75%] xl:w-[80%] ml-auto'>
        <DashboardHeader toggleSidebar={toggleSidebar} />
        <div className='bg-gray-50 min-h-screen p-4'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
