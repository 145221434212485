import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import Btn from "../../Utils/Button/btn";
import { useGlobalContext } from "../../context";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MakeRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState(null);
  const { baseUrl, config, getTransactions } = useGlobalContext();
  const navigateTo = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    setRequests({ ...requests, [name]: val });
  };

  const sendRequest = async () => {
    const url = `${baseUrl}/make-requests`;
    setIsLoading(true);
    try {
      const { data } = await axios.post(url, requests, config());
      toast.success(data.message);
      getTransactions();
      setTimeout(() => {
        navigateTo("/dashboard");
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message || "An error occurred while");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <NavHeader title={"Make Request"} />
      <Form
        onSubmit={sendRequest}
        className={
          "flex flex-col gap-4 bg-white mx-auto w-full max-w-xl p-4 md:p-8 mt-10 rounded-md"
        }
      >
        <TextInput
          title='Customer Name'
          value={requests?.customer_name || ""}
          id='customer_name'
          className={"bg-gray-100"}
          setItem={handleChange}
          required={true}
        />
        <TextInput
          title='Customer Phone'
          value={requests?.customer_phone || ""}
          id='customer_phone'
          type='tel'
          className={"bg-gray-100"}
          setItem={handleChange}
          required={true}
        />
        <TextInput
          title='Customer Address'
          value={requests?.customer_address || ""}
          id='customer_address'
          type='text'
          className={"bg-gray-100"}
          setItem={handleChange}
          required={true}
        />
        <div className='flex items-center justify-center'>
          <Btn
            text={"Create Request"}
            loadingState={isLoading}
            className={"bg-pry px-20 text-white"}
          />
        </div>
      </Form>
    </div>
  );
};

export default MakeRequest;
