import React from "react";
import DateFormatter from "../../Utils/DateFormatter";

const Referrals = ({ item }) => {
  const { created_at: time, amount, id } = item || {};
  console.log(item);
  return (
    <div className='grid text-sm grid-cols-5'>
      <DateFormatter time={time} />
      <h2>{id}</h2>
      <h2
        className={`text-blue-800 font-medium px-3 bg-blue-100 text-center p-1 w-fit rounded-md`}
      >
        Pending
      </h2>
      <h2>₦{amount}</h2>
      <h2>***</h2>
    </div>
  );
};

export default Referrals;
