import React from "react";
import HomePageHero from "../../Components/LandingPages/HomePage/hero";
import Why from "../../Components/LandingPages/HomePage/why";
import How from "../../Components/LandingPages/HomePage/how";
import Review from "../../Components/LandingPages/HomePage/review";

const Home = () => {
  return (
    <div>
      <HomePageHero />
      <Why />
      <How />
      <Review />
    </div>
  );
};

export default Home;
