import React, { useEffect } from "react";
import TermsHero from "../../Components/LandingPages/Terms/hero";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <TermsHero />
      <div className='my-8 container mx-auto p-4 text-gray-700'>
        <h2 className='font-bold'>
          THESE TERMS AND CONDITIONS (THE “TERMS OF USE”) GOVERN YOUR ACCESS TO
          AND USE OF OUR SERVICE. THE SERVICE IS AVAILABLE FOR YOUR USE ONLY ON
          THE CONDITION THAT YOU AGREE TO THE TERMS OF USE SET FORTH BELOW. BY
          ACCESSING OR USING THE SERVICE, EITHER PHYSICALLY OR VIA OUR ECOMMERCE
          MEDIUM, YOU AND ANY ENTITY YOU ARE AUTHORIZED TO REPRESENT (“YOU” OR
          “YOUR PROXY”) SIGNIFY TO BE BOUND BY THE TERMS OF USE.
        </h2>

        <p className='my-4'>
          The General Rules and Tariffs, as set forth by the carriers, will in
          every instance take precedence in all legal proceedings.
        </p>
        <p className='my-4'>
          All Terms, including, but not limited to, all the limitations of
          liability, shall apply to the selected carriers, their agents and
          contracted carriers.
        </p>
        <p className='my-4'>
          We are freight brokers and NOT a freight carrier. We reserve the
          right, in our sole discretion, to refuse any shipment at any time.
        </p>
        <p className='my-4'>
          We shall not be liable for any loss, damage, mis-delivery or
          non-delivery caused by the act, default or omission of the Carrier. We
          are not liable for any loss, mis-delivery or non-delivery caused by
          the act, default or omission of the Customer or any other party who
          claims interest in the shipment, or caused by the nature of the
          shipment or any defect thereof.
        </p>
        <p className='my-4'>
          We are not liable for losses, mis-delivery or non-delivery caused by
          violation(s) by the Customer of any of the TERMS AND CONDITIONS
          contained in the Bill of Lading or of the carrier’s General Rules
          including, but not limited to, improper or insufficient packing,
          securing, marking or addressing, or of failure to observe any of the
          rules relating to shipments, goods of perishable nature or emitting
          obnoxious odours not acceptable for transportation or shipments
          acceptable only under certain conditions.{" "}
        </p>
        <p className='my-4'>
          We are not liable for losses, mis-delivery or non-delivery caused by
          the acts of God, perils of the air, public enemies, public
          authorities, acts or omissions of Customs or quarantine officials,
          war, riots, strikes, labour disputes, weather conditions or mechanical
          delay or failure of aircraft or other equipment.{" "}
        </p>
        <p className='my-4'>
          We are not liable for failure to comply with delivery or other
          instructions from the Customer or for the acts or omissions of any
          person other than our employees.
        </p>
        <p className='my-4'>
          As stated above, Customs conducts inspections at the port of Entry
          into every country; we have no control over the decisions of
          International Customs on inspection and we shall not be liable for
          delay, seizure or destruction of any package by international customs
          for whatever reason.
        </p>
        <p className='my-4'>
          Subject to the limitations of liability contained in the Bill of
          Lading and the carrier’s General Rules, we shall only be liable for
          loss, damage, mis-delivery or non-delivery caused by our own gross
          negligence. Our liability therefore shall be limited to the fees that
          we have earned with respect to the subject shipment.
        </p>
        <h2 className='font-bold'>
          WE CANNOT GUARANTEE DELIVERY BY ANY SPECIFIC TIME OR DATE. IN ANY
          EVENT, WE SHALL NOT BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR
          CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS OR
          INCOME, WHETHER OR NOT WE HAD KNOWLEDGE THAT SUCH DAMAGES MIGHT BE
          INCURRED.
        </h2>
        <h2 className='font-bold my-4 uppercase'>Domestic Transit Insurance</h2>
        <p className='my-4'>
          Through the purchase of our optional quoted cargo insurance, the
          enrolled Customer will receive an insurance policy equal to the
          declared value amount of the submitted goods. Proof of cost by receipt
          and mutual assessment would be required to arrive at this value.
        </p>
        <p className='my-4'>
          We shall bear no responsibility, liability or involvement in the
          issuance of insurance, the denial of insurance, or in the payment of
          claims. In the event of cargo loss or damage, the Certificate Holder
          is to contact the Claim Agent noted on the Certificate of Insurance
          immediately.
        </p>
        <h2 className='font-bold my-4'>
          FAILURE TO PURCHASE CARGO INSURANCE WILL RESULT IN CUSTOMER BEING
          LIMITED TO RECOVERY PURSUANT TO OUR GENERAL RULES WHICH ENTITLES THE
          CUSTOMER TO A REFUND OF ₦10,000.000
        </h2>
        <h2 className='font-bold my-4'>THE COVERAGE</h2>
        <p className='my-4'>
          The Policy covers goods in transit from the port of shipment to the
          port of delivery subject to the terms and conditions contained
          therein. Service coverage is from door to door during the ordinary
          course of transit.
        </p>
        <p className='my-4'>
          Insuring Conditions: All Risks of Physical Loss or Damage from any
          external cause.
        </p>
        <p className='my-4'>
          Exclusions: Inherent Vice, inventory shortages or mysterious
          disappearance, nuclear reaction or nuclear radiation or radioactive
          contamination or insufficient packing or obnoxious odours and perished
          goods.
        </p>
        <p className='my-4'>Limit any one conveyance: $50,000.00</p>
        <p className='my-4 font-bold uppercase'>
          Right to Reject Requests for Shipping Services 
        </p>
        <p className='my-4'>
          We reserve the right to reject any request for shipping in our sole
          discretion. Without limitation, any shipment containing any item that
          is considered a restricted article or hazardous material by
          international shipping standards.
        </p>
        <p className='my-4 font-bold uppercase'>Our Obligations</p>
        <p className='my-4'>
          We, our agents and carriers agree to use commercially reasonable
          efforts to, either directly or indirectly:
        </p>
        <p className='my-4'>
          (a) match the item(s) of each shipment against the item(s) set forth
          on the shipping directions from Customer; <br />
          <br />
          (b) inspect each shipment and note all apparent damage on the
          appropriate freight bill, delivery receipt, or similar document
          evidencing delivery, and notify Customer of such damage; and <br />
          <br />
          (c) deliver all shipments to locations directed by Customer. We may
          ship the items by any means, including truck, air, vessel, or any
          other carrier, unless Customer gives specific electronic or written
          instructions to the contrary.
        </p>
        <p className='my-4 font-bold uppercase'>
          Customer Release of Liability 
        </p>
        <p className='my-4'>
          We shall not be held liable by Customer for the following:
        </p>
        <li className='list-disc ml-6'>
          Accuracy of item description, its contents, or its condition.
        </li>
        <li className='list-disc ml-6'>
          Proper packaging of items except we have specific instructions to pack
          on customers’ behalf.
        </li>
        <li className='list-disc ml-6'>
          Proper processing and preservation of otherwise perishable items.
        </li>
        <li className='list-disc ml-6'>
          tampering, brigandage or otherwise stealing while in transit and not
          covered by insurance.
        </li>
        <li className='list-disc ml-6'>
          Any other limit of liability related to the item being shipped.
        </li>
        <p className='my-4 font-bold uppercase'>Independent Contractor </p>
        <p className='my-4'>
          We are independent contractors with respect to Customer, and nothing
          herein contained shall be construed to be inconsistent with such
          relationship or status.
        </p>
        <p className='my-4'>
          Customer represents and warrants to us as follows: <br />
          <br />
          (a) all items to be shipped will be completely and accurately marked
          to enable identification of the contents without opening any shipping
          or storage containers; <br />
          <br />
          (b) Customer will make every effort to accurately measure the
          dimensions and weights of all items and understands that our rate
          depends upon the accuracy of this information <br />
          <br />
          (c) Customer’s authorized representative(s) shall be identified to our
          agent or coordinator and shall be available at all times at the point
          of origination to sign, and shall sign, all documents evidencing
          pick-up of the items to be shipped by us; and Customer is the legally
          documented owner of all property received by us, and/or is authorized
          to cause such property to be stored and otherwise controlled by us as
          provided in the applicable Bill of Lading.
        </p>
        <p className='my-4 uppercase font-bold'>Force Majeure</p>
        <p className='my-4'>
          Any delay or failure of performance of either party to these TERMS AND
          CONDITIONS shall not constitute a breach or default of these TERMS AND
          CONDITIONS or any Bill of Lading, or give rise to any claims for
          damages, if and to the extent that such delay or failure is caused by
          an occurrence beyond the control of the party affected, including, but
          not limited to, acts of governmental authorities, acts of God, the
          discovery of materially different site conditions, wars, riots,
          rebellions, sabotage, fire, explosions, accidents, floods, strikes,
          lockouts, lockdown due to pandemic or changes in laws, regulations, or
          ordinances. In the event that a party intends to invoke this force
          majeure provision, that party shall provide prompt notice to the other
          party as soon as possible after the occurrence of the event giving
          rise to the claim of force majeure.
        </p>
        <p className='my-4 font-bold uppercase'>Counterparts </p>
        <p className='my-4'>
          These TERMS AND CONDITIONS may be executed in hard copy by the
          Customer or via his/her proxy or checked as accepted via online order
          and will remain binding on all parties until the completion of the
          service.
        </p>
        <p className='my-4 font-bold uppercase'>
          Governing Law and Jurisdiction
        </p>
        <p className='my-4'>
          To the fullest extent permitted by law, these Terms of Use are
          governed by the laws of Federal Republic of Nigeria taking cognizance
          of International Conflicts of laws.{" "}
        </p>
        <p className='my-4 font-bold uppercase'>Complete Agreement </p>
        <p className='my-4'>
          Except as expressly provided in a separate license, service or other
          written agreement, these terms of use constitute the entire agreement
          between you and us.
        </p>
        <p className='my-4'></p>
      </div>
    </div>
  );
};

export default Terms;
