import React, { useState } from "react";
import logo from "../../../Assets/images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Btn from "../../../Utils/Button/btn";
import Sidebar from "./sidebar";
import { BiMenuAltRight } from "react-icons/bi";

const Nav = () => {
  const navLinks = [
    { title: "Home", url: "/" },
    { title: "FAQ", url: "faq" },
    // { title: "Login", url: "login" },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigateTo = useNavigate();

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const token = localStorage.getItem("token");

  return (
    <nav className='absolute top-0 right-0 w-full p-3'>
      <div className='flex justify-between items-center gap-4 container mx-auto'>
        <Link to='/'>
          <img className='w-28' src={logo} alt='' />
        </Link>

        <div className='sm:flex items-center gap-4 hidden'>
          {navLinks.map(({ title, url }, ind) => (
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-gray-400 text-sm font-bold"
                  : isActive
                  ? "text-pry text-sm font-bold"
                  : "text-white text-sm font-bold"
              }
              to={url}
              key={ind}
            >
              {title}
            </NavLink>
          ))}
          {token ? (
            <Link to='dashboard'>
              <Btn className={"text-white text-sm"} text='Dashboard' />
            </Link>
          ) : (
            <Link to='login'>
              <Btn className={"text-white text-sm"} text='Login' />
            </Link>
          )}

          <Link to='signup'>
            <Btn className={"bg-pry text-white text-sm"} text='Sign Up' />
          </Link>
        </div>
        <BiMenuAltRight
          className='text-4xl text-pry sm:hidden cursor-pointer'
          onClick={openSidebar}
        />
      </div>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        navItems={navLinks}
        token={token}
      />
    </nav>
  );
};

export default Nav;
