import React, { useState } from "react";
import logo from "../../../Assets/images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import {
  AddBankIcon,
  DashIcon,
  HelpIcon,
  LogoutIcon,
  MakeRequestIcon,
  ProfileIcon,
  ReferralIcon,
  SettingsIcon,
  TransactionIcon,
  WalletIcon,
} from "../../../Assets/Icons/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-activity";
import { useGlobalContext } from "../../../context";
const DashboardSidebar = ({ closeSidebar }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { baseUrl, config } = useGlobalContext();
  const menuItems = [
    { title: "Dashboard", url: "", icon: <DashIcon /> },
    { title: "Make Request", url: "make-request", icon: <MakeRequestIcon /> },
    { title: "Profile", url: "profile", icon: <ProfileIcon /> },
    { title: "Wallet", url: "wallet", icon: <WalletIcon /> },
    { title: "Referral", url: "referral", icon: <ReferralIcon /> },
    {
      title: "Add Bank Account",
      url: "add-bank-account",
      icon: <AddBankIcon />,
    },
    { title: "Help", url: "help", icon: <HelpIcon /> },
    { title: "Settings", url: "settings", icon: <SettingsIcon /> },
  ];

  const navigateTo = useNavigate();

  const logout = async () => {
    const url = `${baseUrl}/logout`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, {}, config());
      setIsLoading(false);
      localStorage.clear();
      navigateTo("/login");
      window.location.reload();
    } catch (error) {
      localStorage.clear();
      navigateTo("/login");
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
    }
  };

  return (
    <div className='shadow-md h-screen p-6 md:p-8 overflowHide'>
      {/* <ToastContainer autoClose={3000} /> */}
      <div className='flex justify-between'>
        <Link to='/'>
          <img src={logo} className='md:w-40 w-24' alt='logo' />
        </Link>
        <IoCloseOutline
          className='text-3xl text-pry lg:hidden'
          onClick={closeSidebar}
        />
      </div>
      <div className='my-10 text-sm flex flex-col gap-4'>
        {menuItems.map(({ title, url, icon }, ind) => (
          <NavLink
            to={url}
            key={ind}
            onClick={closeSidebar}
            className={({ isActive, isPending }) =>
              isPending
                ? "text-black p-4 rounded-md transition flex gap-2 items-center hover:bg-pry hover:text-white"
                : isActive
                ? "bg-green-50 text-black p-4 rounded-md transition flex gap-2 items-center"
                : "text-black p-4 rounded-md transition flex gap-2 items-center hover:bg-pry hover:text-white"
            }
          >
            <i>{icon}</i>
            {title}
          </NavLink>
        ))}
      </div>
      {isLoading ? (
        <div className='flex items-center justify-center gap-3'>
          Logging Out
          <Spinner size={10} />
        </div>
      ) : (
        <button
          onClick={logout}
          className='w-full bg-pry text-white flex items-center p-3 rounded-md gap-2 justify-center text-sm'
        >
          <LogoutIcon />
          Logout
        </button>
      )}
    </div>
  );
};

export default DashboardSidebar;
