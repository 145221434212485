import React, { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [walletDetails, setWalletDetails] = useState({});
  const [walletTrans, setWalletTrans] = useState([]);
  const [referralTrans, setReferralTrans] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [bankDetails, setBankDetails] = useState(null);
  const [allNotifications, setAllNotifications] = useState([]);
  const token = localStorage.getItem("token");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const [allTrans, setAllTrans] = useState([]);
  // const [walletTrans, setWalletTrans] = useState

  const config = () => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };
  const baseUrl = "https://api.myafrimall.com.ng/api/v1";
  const imageUrl = "https://api.myafrimall.com.ng";
  // const baseUrl = "https://sandbox.myafrimall.com.ng/api/v1";
  // const imageUrl = "https://sandbox.myafrimall.com.ng";

  const getProfile = async () => {
    const url = `${baseUrl}/get-profile`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setProfileDetails(res.data.data);
      setProfileImage(res.data.data.profile_photo);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getDashboard = async () => {
    const url = `${baseUrl}/dashboard`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setDashboardDetails(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    const url = `${baseUrl}/delete-account`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, {}, config());
      toast.success(res.data.message);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  const getWalletBalance = async () => {
    const url = `${baseUrl}/wallet`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setWalletDetails(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
      setIsLoading(false);
    }
  };
  const getWalletTrans = async () => {
    const url = `${baseUrl}/wallet-transactions?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setWalletTrans(res.data.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getNews = async () => {
    const url = `${baseUrl}/get-all-news`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setNews(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getReferralTrans = async () => {
    const url = `${baseUrl}/referral-transactions?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setReferralTrans(res.data.data.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllBanks = async () => {
    const url = `${baseUrl}/banks`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setAllBanks(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getBankDetails = async () => {
    const url = `${baseUrl}/get-bank-details`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setBankDetails(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getTransactions = async () => {
    const url = `${baseUrl}/get-transactions?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setAllTrans(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getNotifications = async () => {
    const url = `${baseUrl}/notifications`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config());
      setAllNotifications(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const readNotification = async (id) => {
    const url = `${baseUrl}/read-notification/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.put(url, {}, config());
      getNotifications();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteNotification = async (id) => {
    const url = `${baseUrl}/delete-notification/${id}`;
    setIsBtnLoading(true);
    try {
      const res = await axios.put(url, {}, config());
      setIsBtnLoading(false);
      getNotifications();
    } catch (error) {
      console.log(error);
      setIsBtnLoading(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        isLoading,
        profileDetails,
        profileImage,
        setIsLoading,
        setProfileImage,
        setProfileDetails,
        getProfile,
        dashboardDetails,
        imageUrl,
        config,
        baseUrl,
        walletDetails,
        walletTrans,
        getDashboard,
        getWalletBalance,
        referralTrans,
        allBanks,
        bankDetails,
        getBankDetails,
        dashboardDetails,
        isLoading,
        getAllBanks,
        getReferralTrans,
        getWalletTrans,
        getNotifications,
        allNotifications,
        readNotification,
        isBtnLoading,
        deleteNotification,
        page,
        setPage,
        news,
        getNews,
        getTransactions,
        allTrans,
        deleteAccount,
        // setToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};
export { AppContext, AppProvider };
