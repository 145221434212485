import "react-activity/dist/library.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./Routes/Route";

function App() {
  return <AppRoutes />;
}

export default App;

// https://documenter.getpostman.com/view/21134321/2s9Xy2QXuH
