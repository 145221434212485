import React, { useState } from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import { FiCopy } from "react-icons/fi";
import { ToastContainer } from "react-toastify";
import { copyText } from "../../Utils/Button/copy";
import { MdDeleteOutline } from "react-icons/md";
import DefaultModal from "../../Utils/Modals/defaultModal";
import { useGlobalContext } from "../../context";
import { useNavigate } from "react-router-dom";
import Btn from "../../Utils/Button/btn";
// import Input from "../../Utils/Form/textInput";
// import Form from "../../Utils/Form/form";
// import Btn from "../../Utils/Button/btn";

const Settings = () => {
  const refID = localStorage.getItem("refID" || "");
  const { deleteAccount, isLoading } = useGlobalContext();
  const [isModalShown, setIsModalShown] = useState(false);
  const navigateTo = useNavigate();
  return (
    <div>
      <ToastContainer autoClose={2000} />
      <NavHeader title={"Settings"} />
      <div className='my-8'>
        <h2 className='text-lg text-gray-800 font-bold'>Affiliate ID</h2>
        <div className='flex my-2 gap-4'>
          <h2 className='bg-white text-pry p-2 border-[1px] border-gray-200 rounded-md'>
            www.affiliate.myafrimall.com.ng/{refID}
          </h2>
          <i
            onClick={() => copyText(`www.affiliate.myafrimall.com.ng/${refID}`)}
            className='bg-white border-[1px] border-gray-200 text-xl p-2 rounded-md hover:scale-75 transition'
          >
            <FiCopy />
          </i>
        </div>
      </div>
      <div className='my-8'>
        <h2 className='text-lg text-gray-800 font-bold'>Terms & Conditions</h2>
        <div className='flex my-2 gap-4'>
          <h2 className='bg-white text-red-600 p-2 border-[1px] border-gray-200 rounded-md'>
            www.affiliate.myafrimall.com.ng/terms-and-conditions
          </h2>
          <i
            onClick={() =>
              copyText(`www.affiliate.myafrimall.com.ng/terms-and-conditions`)
            }
            className='bg-white border-[1px] border-gray-200 text-xl p-2 rounded-md'
          >
            <FiCopy />
          </i>
        </div>
      </div>

      <button
        onClick={() => setIsModalShown(true)}
        className='text-red-600 bg-red-200 p-3 px-6 rounded-md flex items-center gap-2'
      >
        <MdDeleteOutline />
        Delete Account
      </button>
      <DefaultModal
        closeModal={() => setIsModalShown(false)}
        visibilityState={isModalShown}
      >
        <h2 className='font-bold text-xl'> Delete Account</h2>
        <h2>Are you sure you want to delete your account?</h2>

        <Btn
          onClick={async () => {
            const res = await deleteAccount();
            if (res) {
              navigateTo("/login");
            }
          }}
          loadingState={isLoading}
          className='bg-red-600 text-white rounded-lg px-10 mt-4'
          text={"Delete"}
        />
      </DefaultModal>
    </div>
  );
};

export default Settings;
