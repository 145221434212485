import React from "react";
import DateFormatter from "../../Utils/DateFormatter";

const SingleTransaction = ({ item }) => {
  const {
    affiliate_commission,
    created_at,
    customer_address,
    customer_name,
    customer_phone,
    id,
    order_status,
    payment_status: status,
    price,
    user_id,
  } = item || {};
  console.log(item);
  return (
    <>
      <div className='rounded-md grid grid-cols-8 text-xs gap-2'>
        <h2>₦{price || 0}</h2>
        <h2>₦{affiliate_commission || 0}</h2>
        <DateFormatter time={created_at} />
        <h2>{customer_name}</h2>
        <h2>{customer_address}</h2>
        <h2>{customer_phone}</h2>
        <h2>{order_status}</h2>

        <h2
          className={`${
            status === "pending"
              ? "text-sky-700"
              : status === "delivered"
              ? "text-green-700"
              : "text-red-700"
          } capitalize`}
        >
          {status}
        </h2>
        {/* <h2>{transaction_type}</h2> */}
      </div>
    </>
  );
};

export default SingleTransaction;
