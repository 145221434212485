import React, { useState } from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import Btn from "../../Utils/Button/btn";
import WalletCard from "../../Components/Wallet/walletCard";
import {
  TotalWalletBalanceIcon,
  TotalWalletIcon,
  TotalWithdrawalICon,
} from "../../Assets/Icons/icons";
import DashTransactions from "../../Components/Dashboard/transactions";
import Widthdraw from "../../Components/Wallet/widthdraw";
import SetWidthdralPin from "../../Components/Wallet/setWidthdralaPin";
import ResetWithdrawal from "../../Components/Wallet/resetWithdrawal";
import success from "../../Assets/images/success.png";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { Spinner } from "react-activity";
import WalletTransactions from "../../Components/Wallet/transaction";

const Wallet = () => {
  const {
    walletDetails: { data, transfer_pin },
    isLoading,
    walletTrans,
  } = useGlobalContext();

  const wallDetails = [
    {
      title: "Total Income",
      amount: data?.total_income,
      icon: <TotalWalletIcon />,
    },
    {
      title: "Total Withdrawals",
      amount: data?.total_withdrawal,
      icon: <TotalWithdrawalICon />,
    },
    {
      title: "Wallet Balance",
      amount: data?.wallet_balance,
      icon: <TotalWalletBalanceIcon />,
    },
  ];
  const [activePage, setActivePage] = useState(0);

  const nextSlide = () => {
    setActivePage(activePage + 1);
  };

  if (isLoading) {
    return (
      <div className='my-10 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  const isAccountCreated = transfer_pin;

  return (
    <div>
      {activePage === 0 && (
        <div>
          <div className='flex justify-between gap-4'>
            <NavHeader title='Wallet' />
            <Btn
              text='Withdraw Money'
              className='text-sm bg-pry text-white'
              onClick={nextSlide}
            />
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6 place-items-center sm:place-items-start'>
            {wallDetails.map((item, ind) => {
              return <WalletCard item={item} ind={ind} key={ind} />;
            })}
          </div>
          <WalletTransactions />
          {/* <DashTransactions data={walletTrans} /> */}
        </div>
      )}
      {activePage === 1 && (
        <Widthdraw
          isAccountCreated={isAccountCreated}
          setActivePage={setActivePage}
          nextSlide={nextSlide}
        />
      )}
      {activePage === 2 && (
        <div className='flex flex-col items-center justify-center'>
          <img src={success} alt='success' />
          <h2 className='my-3'>
            Withdrawal from your affiliate account successful
          </h2>
          <Link
            to='/dashboard'
            className='text-sm px-10 bg-pry rounded-md p-3 text-white'
          >
            Go back to Home page
          </Link>
        </div>
      )}
      {activePage === 3 && (
        <SetWidthdralPin setActivePage={setActivePage} nextSlide={nextSlide} />
      )}
      {activePage === 4 && <ResetWithdrawal />}
    </div>
  );
};

export default Wallet;
