import React, { useEffect, useState } from "react";
import DashIndex from "../../Components/Dashboard/dashIndex";
import TopAffiliates from "../../Components/Dashboard/topAffiliates";
import News from "../../Components/Dashboard/news";
import DashTransactions from "../../Components/Dashboard/transactions";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";
import { useGlobalContext } from "../../context";

const Dashboard = () => {
  const { dashboardDetails, isLoading } = useGlobalContext();

  return (
    <div>
      <ToastContainer autoClose={3000} />
      {isLoading ? (
        <div className='flex items-center justify-center'>
          {" "}
          <Spinner />{" "}
        </div>
      ) : (
        <>
          <DashIndex
            totalSales={dashboardDetails?.total_sales}
            earnings={dashboardDetails?.total_earning}
          />
          <TopAffiliates data={dashboardDetails?.top_affiliate} />
          <News />
          <DashTransactions />
        </>
      )}
    </div>
  );
};

export default Dashboard;
