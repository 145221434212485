import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import PasswordInput from "../../Utils/Form/passwordInput";
import Btn from "../../Utils/Button/btn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "../../context";

const Signup = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { baseUrl } = useGlobalContext();
  const handleFormData = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSignup = async () => {
    const url = `${baseUrl}/affiliate/register`;
    setIsLoading(true);
    const payload = {
      ...userDetails,
      platform: "affiliate",
      referral_code: "",
    };
    try {
      const res = await axios.post(url, payload);
      setIsLoading(false);
      toast.success(res.data.message);
      setTimeout(() => {
        navigateTo("/verify-otp");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data.message || "Error occured");
    }
  };

  const navigateTo = useNavigate();

  return (
    <div className='container mx-auto p-4'>
      <ToastContainer />
      <Link to='/'>
        <img className='w-40' src={logo} alt='logo' />
      </Link>
      <h2 className='text-2xl font-medium text-center mt-10'>
        Welcome to Myafrimall Affiliate
      </h2>
      <Form className='my-10' onSubmit={handleSignup}>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <TextInput
            required={true}
            title='First Name'
            className='bg-gray-100'
            id='firstname'
            setItem={handleFormData}
          />
          <TextInput
            required={true}
            title='Last Name'
            setItem={handleFormData}
            className='bg-gray-100'
            id='lastname'
          />
          <TextInput
            required={true}
            title='Email'
            type='email'
            className='bg-gray-100'
            id='email'
            setItem={handleFormData}
          />
          <TextInput
            required={true}
            title='Phone Number'
            type='tel'
            id='phone_number'
            setItem={handleFormData}
            className='bg-gray-100'
          />
          <PasswordInput
            title='Password'
            className='bg-gray-100'
            id='password'
            setItem={handleFormData}
            required={true}
          />
          <PasswordInput
            title='Confirm Password'
            className='bg-gray-100'
            id='confirm_password'
            setItem={handleFormData}
            required={true}
          />
          <TextInput
            title='Referral Code'
            className='bg-gray-100'
            id='referral_code'
            setItem={handleFormData}
          />
        </div>
        <div className='max-w-2xl mx-auto flex flex-col items-center justify-center my-8'>
          <Btn
            loadingState={isLoading}
            className='bg-pry text-white w-full'
            text='Sign Up'
          />
          <h6 className='my-2 cursor-pointer select-none'>
            Already have an account?{" "}
            <span className='text-red-600' onClick={() => navigateTo("/login")}>
              Sign in
            </span>
          </h6>
        </div>
      </Form>
    </div>
  );
};

export default Signup;
