import React from "react";
import how from "../../../Assets/images/how.png";

const How = () => {
  return (
    <section className='bg-black text-white p-3 py-16'>
      <div className='container mx-auto'>
        <h2 className='font-bold text-3xl text-center mb-6'>
          So, How Does it Work?
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          <img src={how} alt='how' className='bg-gray-300 p-4 w-full' />
          <div className='text-base'>
            <p>
              Our affiliate program offers a simple and rewarding process. After
              registering and gaining approval, you'll receive a personalized
              affiliate dashboard. Promote our logistics services using unique
              affiliate links and creative materials. Earn competitive
              commissions for successful referrals, with timely and reliable
              payouts.
            </p>
            <li className='list-disc my-5'>
              Simple Registration: Getting started is a breeze! Sign up for our
              affiliate program, and once your application is approved, you'll
              gain access to a personalized affiliate dashboard.
            </li>
            <li className='list-disc my-5'>
              Promote with Creativity: Unleash your marketing prowess! Use your
              unique affiliate links and eye-catching creatives to promote our
              logistics services on your website, blog, social media platforms,
              or through email marketing.
            </li>
            <li className='list-disc my-5'>
              Earn Generously: Every successful referral counts! Earn
              competitive commissions for each customer who avails of our
              logistics services through your affiliate link.
            </li>
            <li className='list-disc my-5'>
              Timely Payouts: Say goodbye to delayed payments! We believe in
              rewarding our affiliates promptly, ensuring you receive your
              commissions on time.
            </li>
          </div>
        </div>
      </div>
    </section>
  );
};

export default How;
