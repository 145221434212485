import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import PasswordInput from "../../Utils/Form/passwordInput";
import Btn from "../../Utils/Button/btn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "../../context";

const Login = () => {
  const [userDetails, setUserDetails] = useState({});
  const { baseUrl, isLoading, setIsLoading } = useGlobalContext();

  const handleFormData = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const navigateTo = useNavigate();

  const handleLogin = async () => {
    const url = `${baseUrl}/login`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, userDetails);
      setIsLoading(false);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("refID", res.data.data.referral_code);
      toast.success(res.data.message);
      setTimeout(() => {
        navigateTo("/dashboard");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data.message || "an error occurred");
      if (
        error.response?.data.message.toLowerCase() ===
        "please verify your email"
      ) {
        setTimeout(() => {
          navigateTo("/resend-otp");
        }, 3000);
      }
    }
  };

  return (
    <div className='container mx-auto p-4'>
      <ToastContainer autoClose={3000} />
      <Link to='/'>
        <img className='w-40' src={logo} alt='logo' />
      </Link>
      <h2 className='text-2xl font-medium text-center mt-10'>
        Welcome to Myafrimall Affiliate
      </h2>
      <Form className='my-10' onSubmit={handleLogin}>
        <div className='max-w-xl mx-auto'>
          <TextInput
            required={true}
            title='Email'
            type='email'
            className='bg-gray-100'
            id='email'
            setItem={handleFormData}
          />
          <PasswordInput
            title='Password'
            className='bg-gray-100'
            id='password'
            setItem={handleFormData}
            required={true}
          />
          <Link
            to='/forgot-password'
            className='my-2 text-xs cursor-pointer select-none'
          >
            Forgot Password?
          </Link>
        </div>
        <div className='max-w-xl mx-auto flex flex-col items-center justify-center my-8'>
          <Btn
            loadingState={isLoading}
            className='bg-pry text-white w-full'
            text='Sign In'
          />
          <h6 className='my-2 cursor-pointer select-none'>
            Don't have an account?{" "}
            <span
              className='text-red-600'
              onClick={() => navigateTo("/Signup")}
            >
              Sign Up
            </span>
          </h6>
        </div>
      </Form>
    </div>
  );
};

export default Login;
