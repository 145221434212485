import React, { useEffect, useState } from "react";
import { RiMenu5Fill } from "react-icons/ri";
import { NotificationIcon } from "../../../Assets/Icons/icons";
import user from "../../../Assets/images/user1.png";
import { useGlobalContext } from "../../../context";
import { Link } from "react-router-dom";

const DashboardHeader = ({ toggleSidebar }) => {
  const [displayImg, setDisplayImg] = useState(null);
  const {
    profileDetails,
    profileImage,
    imageUrl,
    getProfile,
    allNotifications,
  } = useGlobalContext();

  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    if (profileImage === null || profileImage === "null") {
      setDisplayImg(user);
    } else {
      setDisplayImg(`${imageUrl}${profileImage}`);
    }
  });
  const notificationCount = allNotifications?.filter(
    ({ read }) => read === "false"
  )?.length;
  return (
    <>
      <div className='flex justify-between lg:justify-end gap-4 items-center p-6 lg:p-8'>
        <RiMenu5Fill
          className='text-2xl text-pry lg:hidden'
          onClick={toggleSidebar}
        />
        <div className='flex items-center gap-2'>
          <Link to='/dashboard/notifications' className='flex'>
            <NotificationIcon />
            <h2 className='bg-red-100 text-red-800 w-6 h-6 flex items-center justify-center rounded-full relative right-4 bottom-1 font-bold'>
              {notificationCount}
            </h2>
          </Link>

          <img
            src={displayImg}
            alt=''
            className='h-8 w-8 rounded-full object-cover'
          />

          <h2 className='text-base font-light'>
            {profileDetails?.firstname} {profileDetails?.lastname}
          </h2>
        </div>
      </div>
      {/* <ToastContainer autoClose={3000} /> */}
    </>
  );
};

export default DashboardHeader;
