import React from "react";
import com from "../../../Assets/images/com.png";
import globe from "../../../Assets/images/globe.png";
import plane from "../../../Assets/images/plane.png";
import trusted from "../../../Assets/images/trusted.png";
import ded from "../../../Assets/images/ded.png";

const Why = () => {
  const arr = [
    // {
    //   img: trusted,
    //   title: "Trusted Industry Leader",
    //   content:
    //     "With years of experience and a proven track record, our logistics company stands tall as a reliable and distinguished name in the industry. Partnering with us means associating your brand with trustworthiness and excellence.",
    // },
    {
      img: plane,
      title: "Comprehensive Logistics Solution",
      content:
        "From seamless supply chain management to efficient warehousing and timely deliveries, our wide range of logistics services is designed to cater to diverse business needs. As an affiliate, you can confidently promote our solutions, knowing they are designed to deliver exceptional results",
    },
    {
      img: globe,
      title: "Global Reach",
      content:
        "Our logistics network spans the globe, ensuring that businesses across borders can benefit from our services. By partnering with us, you can connect clients with the right logistics solutions, no matter where they are located.",
    },
    {
      img: com,
      title: "Lucrative Commissions",
      content:
        "We value our affiliates and believe in rewarding them for their efforts. Earn attractive commissions for every successful referral, and watch your earnings grow as you continue to drive more business our way",
    },
    {
      img: ded,
      title: "Dedicated Support",
      content:
        " As our affiliate partner, you'll never walk alone. Our dedicated support team will be by your side, providing all the resources and assistance you need to succeed in promoting our logistics services effectively.",
    },
  ];
  return (
    <div className='container mx-auto p-3 py-16'>
      <h2 className='font-bold text-3xl text-center mb-5'>
        Why Partner With Us?
      </h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
        {arr.map(({ img, title, content }, ind) => (
          <div
            key={ind}
            className='flex flex-col items-center justify-center bg-gray-100 p-5 rounded-md'
          >
            <img
              src={img}
              className='w-48 h-48 rounded-md object-cover'
              alt={title}
            />
            <h2 className='font-bold text-lg text-center my-3'>{title}</h2>
            <p className='text-base'>{content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Why;
