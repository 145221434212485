import React from "react";
import FaqHero from "../../Components/LandingPages/FaqPage/hero";
import MainFaq from "../../Components/LandingPages/FaqPage/mainFaq";

const Faqs = () => {
  return (
    <div>
      <FaqHero />
      <div className='my-8 max-w-3xl mx-auto p-4'>
        <MainFaq />
        <div className='flex flex-col justify-center items-center my-10'>
          <h2 className='text-center text-xl font-bold capitalize'>
            Got more questions to ask?
          </h2>
          <a
            href='mailto: contact@afrimall.com'
            target={"__blank"}
            className='bg-pry rounded-md text-white p-3 px-8 text-center w-fit my-4 shadow-xl hover:scale-105 hover:bg-green-700 transition'
          >
            contact@afrimall.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
