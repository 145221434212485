import React, { useState } from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import { useGlobalContext } from "../../context";
import { Spinner } from "react-activity";
import DateFormatter from "../../Utils/DateFormatter";
import DefaultModal from "../../Utils/Modals/defaultModal";

const Notifications = () => {
  const {
    allNotifications,
    isLoading,
    readNotification,
    isBtnLoading,
    deleteNotification,
  } = useGlobalContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <NavHeader title={"Notifications"} />
      <div>
        {isLoading ? (
          <div className='flex items-center justify-center my-19'>
            {" "}
            <Spinner />{" "}
          </div>
        ) : allNotifications.length < 1 ? (
          <h2 className='my-10'>You have not notifications at the moment</h2>
        ) : (
          allNotifications.map(
            ({ details, title, read, created_at, id }, ind) => {
              return (
                <div key={ind} className='my-4 shadow-md p-4'>
                  <h2>
                    {read === "false" && (
                      <span className='text-red-800 text-sm font-bold'>
                        (New!)
                      </span>
                    )}{" "}
                    {title}
                  </h2>
                  <div className='flex justify-between gap-4 mt-2'>
                    <DateFormatter time={created_at} />
                    {isBtnLoading ? (
                      <Spinner />
                    ) : (
                      <span
                        className='text-pry italic cursor-pointer select-none text-sm'
                        onClick={async () => {
                          await readNotification(id);
                          setIsModalOpen(true);
                        }}
                      >
                        View Details
                      </span>
                    )}
                  </div>
                  <DefaultModal
                    visibilityState={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                  >
                    <h2 className='font-bold mb-2'>{title}</h2>
                    <p>{details}</p>
                    {isBtnLoading ? (
                      <Spinner />
                    ) : (
                      <button
                        onClick={async () => {
                          await deleteNotification(id);
                          setIsModalOpen(false);
                        }}
                        className='text-red-600 mt-3 bg-red-100 p-2 rounded-full px-8'
                      >
                        Delete
                      </button>
                    )}
                  </DefaultModal>
                </div>
              );
            }
          )
        )}
      </div>
    </div>
  );
};

export default Notifications;
