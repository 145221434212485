import React, { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { IoRefreshOutline } from "react-icons/io5";
const WalletCard = (item) => {
  const ind = item?.ind;
  const card = item?.item;
  const [isAmountShown, setIsAmountShown] = useState(false);
  return (
    <div
      className={`${
        ind === 0
          ? "bg-blue-600 text-blue-100"
          : ind === 1
          ? "bg-red-600 text-red-100"
          : "bg-green-600 text-green-100"
      } p-8 rounded-md w-full max-w-[370px] h-52 flex flex-col  justify-center`}
    >
      <div className='flex items-center text-xs gap-2'>
        {card.icon}
        <h2>{card.title}</h2>
      </div>
      <div className='flex items-center gap-2 justify-between  my-3'>
        <h2 className='text-2xl font-bold'>
          {" "}
          {isAmountShown ? `₦ ${card.amount}` : "*****"}
        </h2>
        <i onClick={() => setIsAmountShown(!isAmountShown)} className='text-xl'>
          {isAmountShown ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        </i>
      </div>
      <h2 className='font-bold'>Affiliate Account</h2>
      {/* <div className='flex items-center gap-2 justify-between mt-3'>
        <h2 className='text-xs'>Balance last updated on 15yh August, 2023</h2>
        <IoRefreshOutline />
      </div> */}
    </div>
  );
};

export default WalletCard;
