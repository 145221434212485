import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import person1 from "../../../Assets/images/adewale.jpg";
import person2 from "../../../Assets/images/oluwatoni.png";
import person3 from "../../../Assets/images/fiona.jpeg";
import Btn from "../../../Utils/Button/btn";

export default function Review() {
  const SliderData = [
    {
      img: person1,
      name: "Adewale Oretuga",
      comment:
        "I made the decision to resign from my  ₦30,000 job and focus on affiliate marketing late 2021, with my dashboard reading $0 on Myafrimall till 2023 when I started seeing results. My earnings on Myafrimall have reached $498 in just the first month of 2023 - which is big step from my previous earning”",
    },

    {
      img: person2,
      name: "Oluwatoni Gbadeyan",
      comment:
        "I am an optometrist earning a decent N120,000/month income and needed a side hustle to supplement my business. I have been selling on Myafrimall since October 2022, and by January 2023 i had already made 1.3 million naira on the platform, which is roughly my one year salary made in less than 5 months. My income is growing every month.",
    },
    {
      img: person1,
      name: "Kelvin Mark",
      comment:
        "I made the decision to resign from my  ₦30,000 job and focus on affiliate marketing late 2021, with my dashboard reading ₦0 on Myafrimall till 2023 when I started seeing results. My earnings on Myafrimall have reached $498 in just the first month of 2023 - which is big step from my previous earning”",
    },

    {
      img: person3,
      name: "Fiona Nana",
      comment:
        "I am an optometrist earning a decent N120,000/month income and needed a side hustle to supplement my business. I have been selling on Myafrimall since October 2022, and by January 2023 i had already made 1.3 million naira on the platform, which is roughly my one year salary made in less than 5 months. My income is growing every month.",
    },
    // {
    //   img: person1,
    //   name: "Kelvin Mark",
    //   comment:
    //     "I made the decision to resign from my  ₦30,000 job and focus on affiliate marketing late 2021, with my dashboard reading $0 on Myafrimall till 2023 when I started seeing results. My earnings on Myafrimall have reached $498 in just the first month of 2023 - which is big step from my previous earning”",
    // },

    // {
    //   img: person2,
    //   name: "Hannah Oriel",
    //   comment:
    //     "I am an optometrist earning a decent N120,000/month income[$171] and needed a side hustle to supplement my business. I have been selling on Myafrimall since October 2022, and by January 2023 i had already made $2,300 on the platform which is about 1.3 Million+ naira, which is roughly my one year salary made in less than 5 months. My income is growing every month.",
    // },
  ];

  return (
    <div className='py-10 container p-3 mx-auto bg-gray-50'>
      <p className='text-center text-4xl font-bold my-10'>
        Hear from our Beneficiaries
      </p>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        breakpoints={{
          // 640: {
          //   slidesPerView: 2,
          // },
          768: {
            slidesPerView: 2,
          },
        }}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: true }}
      >
        {SliderData.map(({ img, name, comment }, id) => (
          <SwiperSlide key={id}>
            <div className='rounded-lg p-6 flex items-center flex-col mb-20'>
              <img
                src={img}
                className='w-24 h-24 rounded-full object-cover'
                alt={name}
              />
              <p className='text-xl font-bold my-2'>{name}</p>
              <p className='text-sm text-left'>{comment}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className='flex items-center justify-center my-6'>
        <Btn text='See More' className={"bg-pry text-white"} />
      </div> */}
    </div>
  );
}
