import React, { useState } from "react";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import Btn from "../../Utils/Button/btn";
import { ToastContainer } from "react-toastify";

const EditProfile = ({
  handleInputChange,
  profileDetails,
  profileImage,
  setProfileImage,
  editProfileDetails,
  displayImg,
  isEditLoading,
}) => {
  const { lastname, firstname, address, phone_number, state, country } =
    profileDetails || {};

  return (
    <Form
      onSubmit={editProfileDetails}
      className='my-10 bg-white p-4 rounded-md md:p-8'
    >
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <TextInput
          className={"bg-gray-50"}
          id='firstname'
          title='First Name'
          value={firstname || ""}
          setItem={handleInputChange}
        />
        <TextInput
          className={"bg-gray-50"}
          title='Last Name'
          value={lastname || ""}
          id='lastname'
          setItem={handleInputChange}
        />
        <TextInput
          className={"bg-gray-50"}
          title='Address'
          id='address'
          value={address || ""}
          setItem={handleInputChange}
        />
        <TextInput
          className={"bg-gray-50"}
          title='Phone Number'
          id='phone_number'
          value={phone_number || ""}
          setItem={handleInputChange}
        />
        <TextInput
          id='state'
          className={"bg-gray-50"}
          title='State'
          value={state || ""}
          setItem={handleInputChange}
        />
        <TextInput
          className={"bg-gray-50"}
          title='Country'
          id='country'
          value={country || ""}
          setItem={handleInputChange}
        />
      </div>
      <div className=' rounded-full bg-gray-50 w-40 h-40 mx-auto my-4 p-4 flex flex-col items-center justify-center'>
        <label htmlFor='image' className='cursor-pointer text-sm'>
          <img
            src={displayImg}
            className={`${
              profileImage && "w-40 h-40 rounded-full object-cover"
            } mx-auto`}
            alt=''
          />
          <input
            type='file'
            placeholder='Browse to upload your file'
            className='hidden'
            id='image'
            onChange={(e) => setProfileImage(e.target.files[0])}
          />
          {!profileImage && (
            <h2 className='text-center font-light'>Upload profile picture</h2>
          )}
        </label>
      </div>
      <Btn
        loadingState={isEditLoading}
        text='Save'
        className={"bg-pry text-white text-sm"}
      />
    </Form>
  );
};

export default EditProfile;
