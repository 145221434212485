import React, { useState } from "react";
import NavHeader from "../AppLayout/DashboardLayout/navigation";
import Btn from "../../Utils/Button/btn";
import TextInput from "../../Utils/Form/textInput";
import OTP from "../../Utils/Form/OTP";
import axios from "axios";
import { useGlobalContext } from "../../context";
import { ToastContainer, toast } from "react-toastify";

const Widthdraw = ({ nextSlide, setActivePage, isAccountCreated }) => {
  const [otp, setOtp] = useState([]);
  const [amount, setAmount] = useState(0);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { config, baseUrl, getWalletTrans, getTransactions } =
    useGlobalContext();

  const withdraw = async () => {
    setIsBtnLoading(true);
    try {
      const url = `${baseUrl}/withdraw`;
      const res = await axios.post(url, { amount, pin: otp }, config());
      console.log(res);
      toast.success(res.data.nessage);
      setActivePage(2);
      getWalletTrans();
      getTransactions();
      setIsBtnLoading(false);
      //
    } catch (error) {
      console.log(error);
      setIsBtnLoading(false);
      toast.error(error.response.data.message || "an error occurred ");
    }
  };

  console.log(isAccountCreated);

  return (
    <div>
      <ToastContainer autoClose={300} />
      <div className='flex flex-col md:flex-row justify-between gap-4'>
        <NavHeader title='Withdraw Money' />
        {!isAccountCreated && (
          <Btn
            text='Set Withdrawal Pin'
            className='text-sm bg-pry text-white'
            onClick={() => setActivePage(3)}
          />
        )}
      </div>

      <form
        action=''
        className='my-10 max-w-xl flex flex-col mx-auto gap-4 bg-white p-4 rounded-md shadow-md'
        onSubmit={(e) => {
          e.preventDefault();
          withdraw();
        }}
      >
        <TextInput
          title='Enter Amount to be withdrawn (₦)'
          setItem={(e) => setAmount(e.target.value)}
          className={"bg-gray-200"}
          placeholder={"e.g. 500"}
        />
        <div className='flex flex-col justify-center items-center'>
          <h2 className='mb-2'>Enter your 4-digit Pin</h2>
          <OTP secure={true} length={4} setOTP={setOtp} OTP={otp} />
        </div>
        <div className='flex flex-col justify-center items-center'>
          <Btn
            text='Withdraw'
            loadingState={isBtnLoading}
            className='text-sm bg-pry text-white'
            // onClick={() => }
          />
        </div>
      </form>
    </div>
  );
};

export default Widthdraw;
