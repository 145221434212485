import React from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import Table from "../../Utils/Table";
import Referrals from "../../Components/Referral/referral";
import { useGlobalContext } from "../../context";

const Referral = () => {
  const { isLoading, referralTrans } = useGlobalContext();
  const header = [
    "Date",
    "Referral ID",
    "Status",
    "Commission Amount",
    "Actions",
  ];

  return (
    <div>
      <div className='flex justify-between'>
        <NavHeader title={"Referral"} />
        <h2 className=''>
          Total Number of referrals:{" "}
          <span className='bg-green-100 p-1 rounded-full text-green-700 font-bold'>
            {referralTrans?.length}
          </span>{" "}
        </h2>
      </div>
      <div className='mt-8'>
        <Table
          headerContent={header}
          minSize={"800px"}
          loadingState={isLoading}
          cols={5}
          data={referralTrans}
        >
          {referralTrans?.map((item, index) => (
            <div key={index}>
              <Referrals item={item} index={index} />
              <hr className='my-4 border-green-50' />
            </div>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default Referral;
