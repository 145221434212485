import React, { useEffect, useState } from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import Dropdown from "../../Utils/Form/dropdown";
import Btn from "../../Utils/Button/btn";
import { useGlobalContext } from "../../context";
import { Spinner } from "react-activity";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const AddBank = () => {
  const { bankDetails, allBanks, isLoading, baseUrl, config, getBankDetails } =
    useGlobalContext();
  const [accountDetails, setAccountDetails] = useState({});
  const [isBankResolved, setIsBankResolved] = useState(false);
  const [isBankResolving, setIsBankResolving] = useState(false);
  const [account_name, setAccountName] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    if (bankDetails) {
      setIsBankResolved(true);
      setAccountDetails(bankDetails);
      setAccountName(bankDetails?.account_name);
    }
  }, []);

  const handleInputData = (e) => {
    setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
  };
  const banks = allBanks?.map(({ bank_name }) => bank_name);

  const resolveAccount = async () => {
    setIsBankResolving(true);
    const url = `${baseUrl}/resolve-bank-account`;
    const selectedBank = allBanks.find(
      (b) => b.bank_name === accountDetails?.bank_name
    );
    const code = selectedBank?.code;
    try {
      const res = await axios.post(
        url,
        {
          code,
          account_number: accountDetails?.account_number,
        },
        config()
      );
      const account_name = res.data?.data?.data?.account_name;
      setAccountName(account_name);
      toast.success(res.data.data.message);
      setIsBankResolving(false);
      setIsBankResolved(true);
    } catch (error) {
      console.log(error);
      setIsBankResolving(false);
      toast.error(error.response.data.message || "an error occurred");
    }
  };

  const addBankAccount = async () => {
    setIsBtnLoading(true);
    const url = `${baseUrl}/add-bank-account`;
    const selectedBank = allBanks.find(
      (b) => b.bank_name === accountDetails?.bank_name
    );
    console.log(selectedBank);
    const bank_id = selectedBank?.id;
    try {
      const res = await axios.post(
        url,
        {
          bank_id,
          account_number: accountDetails?.account_number,
          account_name,
        },
        config()
      );
      console.log(res);
      setIsBtnLoading(false);
      getBankDetails();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsBtnLoading(false);
      toast.error(error.response.data.message || "an error occurred");
    }
  };

  useEffect(() => {
    if (accountDetails.account_number?.length === 10 && !bankDetails) {
      resolveAccount();
    }
  }, [accountDetails]);

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <NavHeader title={"Add bank Account"} />
      <Form
        onSubmit={addBankAccount}
        className={
          "flex flex-col gap-4 bg-white mx-auto w-full max-w-xl p-4 md:p-8 mt-10 rounded-md"
        }
      >
        <Dropdown
          data={["Select", ...banks]}
          title='Add Bank Name'
          id='bank_name'
          value={accountDetails?.bank_name || ""}
          setItem={handleInputData}
          className={"bg-gray-100"}
        />

        <TextInput
          title='Enter Account Number'
          id='account_number'
          value={accountDetails?.account_number || ""}
          setItem={handleInputData}
          className={"bg-gray-100"}
        />
        {isBankResolving ? (
          <div className='flex items-center justify-center'>
            <Spinner />{" "}
          </div>
        ) : isBankResolved ? (
          <TextInput
            title='Account Name'
            id='account_name'
            // setItem={handleInputData}
            value={account_name}
            readOnly={true}
            className={"bg-gray-100"}
          />
        ) : (
          ""
        )}
        <div></div>
        {!bankDetails && (
          <div className='flex items-center justify-center'>
            <Btn
              text={"Save"}
              loadingState={isBtnLoading}
              className={"bg-pry px-20 text-white"}
            />
          </div>
        )}
      </Form>
    </div>
  );
};

export default AddBank;
