import React, { useEffect } from "react";
import NavHeader from "../AppLayout/DashboardLayout/navigation";
import Btn from "../../Utils/Button/btn";
import { useGlobalContext } from "../../context";

const ProfileHeader = ({ setActiveSlide, activeSlide }) => {
  return (
    <div className='flex justify-between gap-4'>
      <NavHeader onClick={() => setActiveSlide(0)} title='Profile' />
      {activeSlide !== 1 && (
        <Btn
          onClick={() => setActiveSlide(1)}
          text='Edit Profile'
          className='text-sm bg-pry text-white'
        />
      )}
    </div>
  );
};

export default ProfileHeader;
