import React, { useEffect, useState } from "react";
import NavHeader from "../AppLayout/DashboardLayout/navigation";
import Btn from "../../Utils/Button/btn";
import OTP from "../../Utils/Form/OTP";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useGlobalContext } from "../../context";

const SetWidthdralPin = ({ nextSlide, setActivePage }) => {
  const [pin, setPin] = useState([]);
  const [confirm_pin, setConfirmPin] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { config, baseUrl, getWalletBalance } = useGlobalContext();
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);

  const createPin = async () => {
    setIsBtnLoading(true);
    try {
      const url = `${baseUrl}/create-pin`;
      const res = await axios.post(url, { pin, confirm_pin }, config());
      console.log(res);
      setIsError(false);
      setMsg(res.data.msg);
      setConfirmPin("");
      setPin("");
      setIsBtnLoading(false);
      getWalletBalance();
      setActivePage(1);
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsBtnLoading(false);
      setMsg(error.response.data.message || "an error occurred");
    }
  };

  useEffect(() => {
    if (msg.length > 0) {
      setTimeout(() => {
        setMsg("");
        setIsError(false);
      }, 3000);
    }
  }, [msg]);

  return (
    <div>
      <div className='flex flex-col md:flex-row justify-between gap-4'>
        <NavHeader title='Set Withdrawal Pin' />
        <Btn
          text='Reset Withdrawal Pin'
          className='text-sm bg-pry text-white'
          onClick={nextSlide}
        />
      </div>
      <div className='flex flex-col justify-center items-center gap-4 bg-white rounded-md p-4 shadow-md mt-10'>
        {msg && (
          <p
            className={`${
              isError
                ? "bg-red-200 text-red-600"
                : "bg-green-200 text-green-600"
            } w-fit rounded-md capitalize p-2`}
          >
            {msg}
          </p>
        )}

        <div>
          <h2 className='mb-2'>Enter a 4-digit Pin</h2>
          <OTP secure={true} length={4} setOTP={setPin} OTP={pin} />
        </div>
        <div className=''>
          <h2 className='mb-2'>Confirm your 4-digit Pin</h2>
          <OTP
            secure={true}
            length={4}
            setOTP={setConfirmPin}
            OTP={confirm_pin}
          />
        </div>
        <div className=''>
          <Btn
            text='Set Withdrawal Pin'
            className='text-sm bg-pry text-white px-10'
            loadingState={isBtnLoading}
            onClick={createPin}
          />
        </div>
      </div>
    </div>
  );
};

export default SetWidthdralPin;
