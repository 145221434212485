export const faqs = [
  {
    quest: "How do i become an affiliate?",
    ans: "Kindly register on our website at www.myafrimall.com/affiliate to get started",
  },
  {
    quest: "How do i refer people to Myafrimall Logistics?",
    ans: "Every affiliate will have a reference link that can be shared with people. Once the person transacts, it counts for the affiliate. ",
  },
  {
    quest: "What’s my percentage for every person that i refer?",
    ans: "Every affiliate gets a 4% commission on the first transaction done by the client and 2.5% on subsequent jobs done by the client.",
  },
  {
    quest: "How do i withdraw money from my affiliate account?",
    ans: "All withdrawals can be done from the affiliate dashboard and same will be credited within 24-48hours. ",
  },
  {
    quest:
      "How will i be able to tell when someone i referred has used Myafrimall logistics Services?",
    ans: "All transactions done by your clients will show on the affiliate dashboard.",
  },
  {
    quest:
      "How long will it take for the money i withdrew from my affiliate account to reflect in my bank account?",
    ans: "24-48hours after withdrawal has been initiated.",
  },
];
