import React from "react";
import NavHeader from "../../Components/AppLayout/DashboardLayout/navigation";
import MainFaq from "../../Components/LandingPages/FaqPage/mainFaq";

const Help = () => {
  return (
    <div>
      <NavHeader title={"Help"} />
      <div className='bg-white p-4 md:p-8 my-8 rounded-md'>
        <h2 className='text-lg text-gray-800 font-bold'>
          Introduction to Affiliate Programme
        </h2>
        <p className='text-gray-800 mt-2'>
          Myafrimall logistics is a company that was founded in the year 2010
          and has been active ever since then. We are a pioneering logistics
          company that specializes in bridging continents by seamlessly
          integrating air freight and sea shipping solutions. With a commitment
          to reliability, efficiency, and customer satisfaction, MyAfrimall
          Logistics has established itself as a trusted partner in global supply
          chain management. You can earn money by referring our services to
          People. By doing this,you earn for every person who uses our services.
        </p>
      </div>
      <div className='bg-white p-4 md:p-8 my-8 rounded-md'>
        <h2 className='text-lg text-gray-800 font-bold'>Getting Started</h2>
        <p className='text-gray-900 mt-2'>
          Step 1: Click on www .myafrimalllogistics.com and click on become an
          affiliat
        </p>
        <p className='text-gray-900 mt-2'>
          Step 2: Click on Sign Up if you don’t have an account but if you have
          an account click on Sign in
        </p>
        <p className='text-gray-900 mt-2'>
          Step 3: Enter the code sent to your email{" "}
        </p>
        <p className='text-gray-900 mt-2'>Step 4: Sign in</p>
      </div>
      <div className='bg-white p-4 md:p-8 my-8 rounded-md'>
        <h2 className='text-lg text-gray-800 font-bold'>
          Frequently Asked Questions(FAQ’S)
        </h2>
        <MainFaq />
      </div>
    </div>
  );
};

export default Help;
