import React from "react";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";

const ProfileDetails = ({ profileDetails }) => {
  const { lastname, firstname, address, phone_number, state, country } =
    profileDetails || {};
  return (
    <Form className='grid grid-cols-1 md:grid-cols-2 gap-4 my-10 bg-white p-4 rounded-md md:p-8'>
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='First Name'
        value={firstname}
      />
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='Last Name'
        value={lastname}
      />
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='Address'
        value={address || "null"}
      />
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='Phone Number'
        value={phone_number}
      />
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='State'
        value={state || "null"}
      />
      <TextInput
        className={"bg-green-50"}
        readOnly={true}
        title='Country'
        value={country || "null"}
      />
    </Form>
  );
};

export default ProfileDetails;
