import React from "react";
import { faqs } from "../../../Utils/API/faqsItem";
import Singlefaq from "./singleFaq";

const MainFaq = () => {
  return (
    <div className=''>
      {faqs.map((faq, index) => {
        return <Singlefaq key={index} {...faq} />;
      })}
    </div>
  );
};

export default MainFaq;
