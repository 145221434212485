import React, { useState } from "react";
import NavHeader from "../AppLayout/DashboardLayout/navigation";
import Btn from "../../Utils/Button/btn";
import OTP from "../../Utils/Form/OTP";

const ResetWithdrawal = () => {
  const [otp, setOtp] = useState([]);
  return (
    <div>
      <div className='flex justify-between gap-4'>
        <NavHeader title='Reset Withdrawal Pin' />
        {/* <Btn
          text='Reset Withdrawal Pin'
          className='text-sm bg-pry text-white'
          // onClick={nextSlide}
        /> */}
      </div>
      <div className='flex flex-col justify-center items-center gap-4 bg-white rounded-md p-4 shadow-md mt-10'>
        <div>
          <h2 className='mb-2'>Enter the OTP code sent to your email</h2>
          <OTP length={4} setOTP={setOtp} OTP={otp} />
        </div>
        <div className=''>
          <h2 className='mb-2'>Enter your new 4 digit pin</h2>
          <OTP length={4} setOTP={setOtp} OTP={otp} />
        </div>
        <div className=''>
          <h2 className='mb-2'>Confirm your new 4 digit pin</h2>
          <OTP length={4} setOTP={setOtp} OTP={otp} />
        </div>
        <div className=''>
          <Btn text='Save' className='text-sm bg-pry text-white px-24' />
        </div>
        <p className='text-red-600 text-sm'>
          Please note that the OTP code will only be sent once{" "}
        </p>
      </div>
    </div>
  );
};

export default ResetWithdrawal;
