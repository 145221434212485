import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../../Assets/images/logo.png";
import Btn from "../../../Utils/Button/btn";

const Sidebar = ({ isSidebarOpen, closeSidebar, navItems, token }) => {
  const navigateTo = useNavigate();
  return (
    <aside
      className={`${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } fixed w-full h-screen bg-white top-0 right-0 transition p-8 lg:hidden z-20`}
    >
      <div className='flex justify-between items-center'>
        <img
          onClick={() => {
            navigateTo("/");
            closeSidebar();
          }}
          src={logo}
          className='w-24'
          alt=''
        />
        <i onClick={closeSidebar} className='text-2xl flex justify-end'>
          <MdOutlineClose className='' />
        </i>
      </div>
      <div className='flex flex-col gap-6 my-10'>
        {navItems.map(({ url, title }, index) => {
          return (
            <NavLink
              key={index}
              // className='block whitespace-nowrap text-lg font-medium capitalize border-b-2 py-4'
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-gray-400 font-bold whitespace-nowrap text-lg capitalize border-b-2 py-4"
                  : isActive
                  ? "text-pry font-bold whitespace-nowrap text-lg capitalize border-b-2 py-4"
                  : "text-green-900 whitespace-nowrap text-lg capitalize border-b-2 py-4 font-bold"
              }
              to={url}
              onClick={closeSidebar}
            >
              {title}
            </NavLink>
          );
        })}
      </div>
      {token ? (
        <Link
          className='text-green-900 whitespace-nowrap text-lg capitalize mb-4 block font-bold'
          to='dashboard'
        >
          Dashboard
        </Link>
      ) : (
        <Link
          className='text-green-900 whitespace-nowrap text-lg capitalize mb-4 block font-bold'
          to='login'
        >
          Login
        </Link>
      )}

      <Btn
        onClick={() => navigateTo("/signup")}
        text='Sign up'
        className='bg-pry text-white text-lg'
      />
      <img src={logo} className='absolute bottom-0 right-0 opacity-10' alt='' />
    </aside>
  );
};

export default Sidebar;
