import React from "react";

const FaqHero = () => {
  return (
    <div className='bg-cover bg-center bg-no-repeat min-h-[800px] flex flex-col items-center justify-center faqsPageHero'>
      <div className='max-w-2xl mx-auto text-center p-3'>
        <h2 className='font-bold text-5xl md:text-7xl text-pry'>
          Frequently Asked <span className='text-red-700'>Questions</span>
        </h2>
        <p className='text-white text-lg my-5 font-medium'>
          Answers to Your Questions: Navigating the Unknown with Clarity
        </p>
      </div>
    </div>
  );
};

export default FaqHero;
