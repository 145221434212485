import React from "react";
import logo from "../../../Assets/images/logo.png";
import ig from "../../../Assets/images/ig.png";
import ln from "../../../Assets/images/ln.png";
import fb from "../../../Assets/images/fb.png";
import tw from "../../../Assets/images/tw.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerLinks = [
    {
      title: "Quick Links",
      subLinks: [
        { text: "Visit our store", url: "/" },
        { text: "Our Services", url: "/" },
        { text: "Know more about us", url: "/" },
      ],
    },
    {
      title: "Important Links",
      subLinks: [
        { text: "Privacy Policy", url: "" },
        { text: "Terms & Conditions", url: "/terms-and-conditions" },
      ],
      social: [
        { img: fb, url: "https://facebook.com/myafrimallofficial" },
        { img: tw, url: "https://twitter.com/myafrimall" },
        { img: ig, url: "https://www.instagram.com/myafrimall/" },
        { img: ln, url: "/" },
      ],
    },
  ];
  return (
    <footer className='bg-black text-green-100 p-3 py-20'>
      <div className='mx-auto container grid grid-cols-1 sm:grid-cols-2 gap-8'>
        <div>
          <img src={logo} className='w-40' alt='' />
          <p className='my-4 text-sm'>
            myafrimall logistics is an independent customs broker/customs
            intermediary dedicated to providing solutions to all customs
            clearance needs whether import or export.{" "}
          </p>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          {footerLinks.map(({ title, subLinks, social }, ind) => (
            <div key={ind}>
              <h2 className='text-pry font-medium'>{title}</h2>
              <div>
                {subLinks.map(({ text, url }, ind2) => (
                  <Link
                    to={url}
                    className='block my-4 text-sm hover:text-white transition'
                    key={ind2}
                  >
                    {text}
                  </Link>
                ))}
              </div>
              <div className='flex gap-3'>
                {social?.map(({ img, url }, ind3) => (
                  <a href={url} key={ind3} target='__blank' className=''>
                    <img src={img} className='w-5 h-5' alt='social' />
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
