import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import done from "../../Assets/images/done.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../Utils/Form/form";
import TextInput from "../../Utils/Form/textInput";
import PasswordInput from "../../Utils/Form/passwordInput";
import Btn from "../../Utils/Button/btn";
import OTP from "../../Utils/Form/OTP";
import Timeout from "../../Utils/Form/countdown";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "../../context";

const ResendOTP = () => {
  const [userDetails, setUserDetails] = useState({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { baseUrl, config } = useGlobalContext();

  const handleFormData = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const navigateTo = useNavigate();

  const sendEmail = async () => {
    const url = `${baseUrl}/resend-otp`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, userDetails, config());
      setIsLoading(false);
      setActiveSlide(1);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
    }
  };

  const verify = async () => {
    const url = `${baseUrl}/verify-email`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, { token: otp });
      setIsLoading(false);
      toast.success(res.data.message);
      setTimeout(() => {
        setActiveSlide(2);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
    }
  };

  const getOTP = async (e) => {
    setActiveSlide(0);
  };

  return (
    <div className='container mx-auto p-4'>
      <ToastContainer autoClose={3000} />
      <Link to='/'>
        <img className='w-40' src={logo} alt='logo' />
      </Link>
      {activeSlide !== 2 && (
        <h2 className='text-2xl font-medium text-center mt-10'>Verify Email</h2>
      )}

      {activeSlide === 1 ? (
        <Form className='my-10' onSubmit={verify}>
          <div className='max-w-xl mx-auto'>
            <h2 className='text-base font-medium mb-2'>
              Enter the code sent to your email
            </h2>
            <OTP OTP={otp} setOTP={setOtp} length={4} />
            <div className='my-2'>
              <Timeout duration={156000} resend={getOTP} />
            </div>
          </div>
          <div className='max-w-xl mx-auto flex flex-col items-center justify-center my-8'>
            <Btn
              loadingState={isLoading}
              className='bg-pry text-white w-full'
              text='Verify'
            />
          </div>
        </Form>
      ) : activeSlide === 2 ? (
        <div className='flex flex-col items-center justify-center my-10'>
          <img src={done} alt='done' />
          <h2 className='text-base font-medium mb-2'>
            Your email has been verified successfully.
          </h2>
          <Btn
            onClick={() => navigateTo("/login")}
            className='bg-pry text-white w-full max-w-xl'
            text='Continue to Sign In'
          />
        </div>
      ) : (
        <Form className='my-10' onSubmit={sendEmail}>
          <div className='max-w-xl mx-auto'>
            <TextInput
              required
              title='Email'
              type='email'
              className='bg-gray-100'
              id='email'
              setItem={handleFormData}
            />
          </div>
          <div className='max-w-xl mx-auto flex flex-col items-center justify-center my-8'>
            <Btn
              loadingState={isLoading}
              className='bg-pry text-white w-full'
              text='Continue'
            />
            {/* <h6 className='my-2 cursor-pointer select-none'>
              Didn't recieve email?{" "}
              <span
                className='text-red-600'
                onClick={() => navigateTo("/Signup")}
              >
                Resend
              </span>
            </h6> */}
          </div>
        </Form>
      )}
    </div>
  );
};

export default ResendOTP;
