import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import OTP from "../../Utils/Form/OTP";
import Timeout from "../../Utils/Form/countdown";
import { Link, useNavigate } from "react-router-dom";
import Btn from "../../Utils/Button/btn";
import Form from "../../Utils/Form/form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "../../context";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const { baseUrl } = useGlobalContext();

  const verify = async () => {
    const url = `${baseUrl}/verify-email`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, { token: otp });
      toast.success(res.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
      setIsLoading(false);
    }
  };

  const getOTP = async () => {
    navigate("/resend-otp");
  };

  return (
    <div className='container mx-auto p-4'>
      <ToastContainer autoClose={3000} />
      <Link to='/'>
        <img className='w-40' src={logo} alt='logo' />
      </Link>
      <h2 className='text-2xl font-medium text-center mt-10'>
        Enter the code sent to your email
      </h2>
      <Form
        className={"flex flex-col items-center justify-center my-10"}
        onSubmit={verify}
      >
        <OTP OTP={otp} setOTP={setOtp} length={4} />
        <div className='my-2'>
          <Timeout duration={156000} resend={getOTP} />
        </div>
        <Btn
          loadingState={isLoading}
          text={"Verify"}
          className='bg-pry my-6 text-white'
        />
      </Form>
    </div>
  );
};

export default VerifyOTP;
