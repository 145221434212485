import React from "react";
import DateFormatter from "../../Utils/DateFormatter";

const SingleWalletTransaction = ({ item }) => {
  const {
    amount,
    amount_type,
    created_at,
    details,
    id,
    transaction_type,
    user_id,
  } = item || {};
  console.log(item);
  return (
    <>
      <div className='rounded-md grid grid-cols-5 text-xs gap-2'>
        <h2>₦{amount || 0}</h2>
        <DateFormatter time={created_at} />
        <h2>{amount_type}</h2>
        <h2>{details}</h2>
        <h2>{transaction_type}</h2>
      </div>
    </>
  );
};

export default SingleWalletTransaction;
