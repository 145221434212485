import React from "react";
import { useGlobalContext } from "../../context";
import userImg from "../../Assets/images/user1.png";

const TopAffiliates = ({ data }) => {
  const { imageUrl } = useGlobalContext();
  // console.log(data);
  return (
    <div>
      <div className='flex gap-4 justify-between'>
        <h2 className='font-bold text-2xl'>Top Earners</h2>
        <span className='cursor-pointer select-none text-pry text-sm'>
          View all
        </span>
      </div>
      <div className='overflow-x-scroll'>
        <div className='grid grid-cols-5 gap-4 bg-white p-8 rounded-md shadow-md mt-5 min-w-[1000px]'>
          {data?.map(({ user, wallet_credit }, ind) => {
            let img;
            if (user?.profile_photo) {
              img = `${imageUrl}${user?.profile_photo}`;
            } else {
              img = userImg;
            }

            return (
              <div
                key={ind}
                className='flex flex-col items-center justify-center gap-2'
              >
                <img
                  src={img}
                  className='w-28 h-28 rounded-full object-cover'
                  alt='image'
                />
                <h2 className='font-bold text-lg'>
                  {user?.firstname} {user?.lastname}
                </h2>
                <h2 className='text-pry font-bold'>₦{wallet_credit} earned</h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopAffiliates;
