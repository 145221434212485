import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/LandingPage/Home";
import LandingPage from "../Pages/LandingPage";
import Login from "../Pages/Auth/login";
import Signup from "../Pages/Auth/singup";
import VerifyOTP from "../Pages/Auth/verifyOTP";
import ForgotPassword from "../Pages/Auth/forgotPassword";
import Dashboard from "../Pages/Dashboard/Dashboard";
import DashboardWrapper from "../Pages/Dashboard";
import Profile from "../Pages/Dashboard/Profile";
import Wallet from "../Pages/Dashboard/Wallet";
import Referral from "../Pages/Dashboard/Referral";
import Transactions from "../Pages/Dashboard/Transactions";
import AddBank from "../Pages/Dashboard/AddBank";
import Help from "../Pages/Dashboard/Help";
import Settings from "../Pages/Dashboard/Settings";
import ResendOTP from "../Pages/Auth/resendOTP";
import Notifications from "../Pages/Dashboard/Notifications";
import Faqs from "../Pages/LandingPage/Faqs";
import Terms from "../Pages/LandingPage/Terms";
import MakeRequest from "../Pages/Dashboard/MakeRequest";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<LandingPage />}>
          <Route index element={<Home />} />
          <Route path='faq' element={<Faqs />} />
          <Route path='terms-and-conditions' element={<Terms />} />
        </Route>

        <Route path='login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/verify-otp' element={<VerifyOTP />} />
        <Route path='/resend-otp' element={<ResendOTP />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        {/* <Route path='/login' element={<h2> Login </h2>} /> */}

        {/* USER DASHBOARD */}
        <Route path='/dashboard' element={<DashboardWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path='profile' element={<Profile />} />
          <Route path='wallet' element={<Wallet />} />
          <Route path='referral' element={<Referral />} />
          <Route path='transaction-history' element={<Transactions />} />
          <Route path='add-bank-account' element={<AddBank />} />
          <Route path='help' element={<Help />} />
          <Route path='settings' element={<Settings />} />
          <Route path='notifications' element={<Notifications />} />
          <Route path='make-request' element={<MakeRequest />} />
        </Route>

        <Route path='*' element={<h2>404</h2>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
{
  /* <Route path='track/:trackID/' element={<Track />} /> */
}
