import React, { useEffect } from "react";
import ProfileHeader from "../../Components/Profile/profileHeader";
import { useState } from "react";
import TopAffiliates from "../../Components/Dashboard/topAffiliates";
import ProfileDetails from "../../Components/Profile/profileDetails";
import EditProfile from "../../Components/Profile/editProfile";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";
import upload from "../../Assets/images/upload.png";
import { useGlobalContext } from "../../context";

const Profile = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [displayImg, setDisplayImg] = useState("");

  const {
    isLoading,
    // setIsloading,
    profileDetails,
    profileImage,
    setProfileDetails,
    setProfileImage,
    getProfile,
    dashboardDetails,
    baseUrl,
    config,
    imageUrl,
  } = useGlobalContext();

  const editProfileDetails = async () => {
    setIsEditLoading(true);
    const url = `${baseUrl}/update-profile`;
    const data = { ...profileDetails, profile_photo: profileImage };
    let formInput = new FormData();
    for (const key in data) {
      formInput.append(key, data[key]);
    }
    try {
      const res = await axios.post(url, formInput, config());
      setActiveSlide(0);
      toast.success(res.data.message);
      setIsEditLoading(false);
      getProfile();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message || "an error occurred");
      setIsEditLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (profileImage === null || profileImage === "null") {
      setDisplayImg(upload);
    } else if (profileImage.name) {
      setDisplayImg(URL.createObjectURL(profileImage));
    } else {
      setDisplayImg(`${imageUrl}${profileImage}`);
    }
  }, [profileImage]);

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <ProfileHeader
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
      />
      {isLoading ? (
        <div className='flex items-center justify-center my-10'>
          {" "}
          <Spinner />{" "}
        </div>
      ) : (
        <div className=''>
          {activeSlide === 1 ? (
            <EditProfile
              profileDetails={profileDetails}
              handleInputChange={handleInputChange}
              setProfileImage={setProfileImage}
              profileImage={profileImage}
              editProfileDetails={editProfileDetails}
              baseUrl={baseUrl}
              displayImg={displayImg}
              isEditLoading={isEditLoading}
            />
          ) : (
            <>
              <ProfileDetails profileDetails={profileDetails} />
              <TopAffiliates data={dashboardDetails?.top_affiliate} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
