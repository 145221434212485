import React from "react";

const HomePageHero = () => {
  return (
    <div className='bg-cover bg-center bg-no-repeat min-h-[800px] flex flex-col items-center justify-center homePageHero'>
      <div className='max-w-2xl mx-auto text-center p-3'>
        <h2 className='font-bold text-5xl md:text-7xl text-pry'>
          Join MyAfrimall <span className='text-red-700'>Affiliate</span>
        </h2>
        <p className='text-white text-lg my-5 font-medium'>
          Unlock the power of seamless logistics with our trusted company,
          streamlining supply chains and delivering success. Join our affiliate
          program today and earn while enhancing businesses worldwide."
        </p>
      </div>
    </div>
  );
};

export default HomePageHero;
