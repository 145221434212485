import React from "react";
import { useGlobalContext } from "../../context";

const News = () => {
  const { news, imageUrl } = useGlobalContext();

  if (news.length < 1) {
    return <h2>No News to display at the moment</h2>;
  }
  return (
    <div className='my-10'>
      <div className='flex gap-4 justify-between'>
        <h2 className='font-bold text-2xl'>News</h2>
        <span className='cursor-pointer select-none text-pry text-sm'>
          View all
        </span>
      </div>
      <div className='mt-5 flex flex-col gap-8 bg-white p-4 md:p-8 rounded-md shadow-md'>
        {news?.map((newsCont, ind) => {
          const { news_title, news_details, news_image, id } = newsCont || {};
          return (
            <div key={ind} className='flex flex-row gap-4 items-center'>
              <img
                src={`${imageUrl}${news_image}`}
                className='w-20 h-32 sm:h-20 object-cover'
                alt={"image"}
              />
              <div className='flex flex-col sm:flex-row justify-between gap-2 w-full'>
                <div>
                  <h2 className='font-bold text-lg'>{news_title}</h2>
                  <p className='font-light text-sm'>
                    {news_details.slice(0, 120)}...
                  </p>
                </div>
                <span className='cursor-pointer select-none text-pry text-xs whitespace-nowrap'>
                  Read more
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default News;
