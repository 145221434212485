import React, { useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

const Singlefaq = ({ quest, ans }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className='cursor-pointer' onClick={() => setShow(!show)}>
        <div className='bg-gray-100 shadow-md p-4 my-6 flex items-center justify-between'>
          <h5 className='text-base capitalize font-medium'>{quest}</h5>
          <button>{show ? <AiOutlineClose /> : <AiOutlinePlus />}</button>
        </div>
        {show && <p className='text-sm ml-4'>{ans}</p>}
      </div>
    </>
  );
};
export default Singlefaq;
