import React from "react";
import Table from "../../Utils/Table";
import { useGlobalContext } from "../../context";
import SingleWalletTransaction from "./singleWalletTrans";

const WalletTransactions = () => {
  const { walletTrans, isLoading } = useGlobalContext();
  const header = [
    "Amount",
    "Date",
    "Amount Type",
    "Details",
    "Transaction Type",
  ];
  return (
    <div className='my-10'>
      <div className='flex gap-4 justify-between mb-5'>
        <h2 className='font-bold text-2xl'>Transactions</h2>
        <span className='cursor-pointer select-none text-pry text-sm'>
          View all
        </span>
      </div>
      <Table
        loadingState={isLoading}
        cols='5'
        minSize='1200px'
        headerContent={header}
        data={walletTrans}
      >
        {walletTrans?.map((item, index) => (
          <div key={index}>
            <SingleWalletTransaction item={item} index={index} />
            <hr className='my-4 border-green-50' />
          </div>
        ))}
      </Table>
    </div>
  );
};

export default WalletTransactions;
