import React from "react";
import SingleTransaction from "./singleTransaction";
import Table from "../../Utils/Table";
import { useGlobalContext } from "../../context";

const DashTransactions = ({ data }) => {
  const { allTrans, isLoading } = useGlobalContext();
  const header = [
    "Amount",
    "Commission",
    "Date",
    "Name",
    "Address",
    "Phone",
    "Order Status",
    "Payment Status",
  ];
  // console.log(allTrans);
  return (
    <div className='my-10'>
      <div className='flex gap-4 justify-between mb-5'>
        <h2 className='font-bold text-2xl'>Transactions</h2>
        <span className='cursor-pointer select-none text-pry text-sm'>
          View all
        </span>
      </div>
      <Table
        loadingState={isLoading}
        cols='8'
        minSize='1200px'
        headerContent={header}
        data={allTrans}
      >
        {allTrans?.map((item, index) => (
          <div key={index}>
            <SingleTransaction item={item} index={index} />
            <hr className='my-4 border-green-50' />
          </div>
        ))}
      </Table>
    </div>
  );
};

export default DashTransactions;
