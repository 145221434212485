import React from "react";
import { ArrowForwardIcon, HomeIcon } from "../../../Assets/Icons/icons";
import { Link } from "react-router-dom";

const NavHeader = ({ title, onClick }) => {
  return (
    <div className='flex items-center gap-3 text-sm'>
      <div className='flex gap-2 items-center cursor-pointer select-none'>
        <HomeIcon />
        <Link to='/dashboard'>Home</Link>
      </div>
      <ArrowForwardIcon />
      <h2 onClick={onClick} className='text-pry cursor-pointer select-none'>
        {title}
      </h2>
    </div>
  );
};

export default NavHeader;
