import React from "react";
import {
  CustomerIcon,
  EarningsIcon,
  HomeIcon,
  ArrowForwardIcon,
  CalendarIcon,
  TransactionIcon,
} from "../../Assets/Icons/icons";
import { months } from "../../Utils/DateFormatter";
import NavHeader from "../AppLayout/DashboardLayout/navigation";

const DashIndex = ({ earnings, totalSales }) => {
  const date = new Date();
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  const dash = [
    {
      text: "Total Earnings",
      icon: <EarningsIcon />,
      num: `₦${earnings || 0}`,
    },
    {
      text: "Total Number of sales",
      icon: <TransactionIcon />,
      num: totalSales,
    },
  ];
  return (
    <div>
      <div className='flex justify-between gap-4'>
        <NavHeader title='Dashboard' />
        <div className='flex items-center gap-2'>
          <CalendarIcon />
          <h2>
            {months[month]} {day}, {year}
          </h2>
        </div>
      </div>
      <div className='my-10 grid grid-cols-1 md:grid-cols-3 gap-6'>
        {dash.map(({ text, icon, num }, ind) => (
          <div key={ind} className='flex gap-3 bg-white p-5 rounded-md'>
            {icon}
            <div>
              <h2 className='text-lg'>{text}</h2>
              <h2 className='text-2xl font-bold'>{num}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashIndex;
